.modal {
	@include clearfix();
	width: calc(100% - 60px);
	max-width: 860px;
	padding: 20px;
	margin: 0 auto;
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.38);
	z-index: 99;
	position: absolute;
	top: 220px;
	left: 30px;
	display: none;

	iframe {
		width: 100%;
	}
}

@include media('small-tablet') {

	.modal {
		width: calc(100% - 80px);
		left: 40px;
		padding: 60px;
	}
}

@include media('desktop') {

	.modal {
		width: 900px;
		left: 50%;
		top: 285px;
		transform: translateX(-50%);
	}
}