.listing--news {
	margin: 70px auto;
    float: none;
    max-width: 880px;
    padding: 0px;
    overflow: hidden;

    li {
    	float: left;
    	margin-bottom: 70px;
    	padding: 0px;
    	position: relative;
    	width: 50%;

    	&:nth-child(odd){
    		padding-right: 5px;
    	}

    	&:nth-child(even){
    		padding-left: 5px;
    	}

    	&:nth-child(2n+1){
    		clear: left;
    	}

    	.listing--content {
    		overflow: hidden;
    	}

    	img {
    		display: block;
    		margin-bottom: 6px;
    	}

    	h2 {
    		font-size: em(20);
    		margin-bottom: 4px;
    		padding-bottom: 0px;
    		text-align: left;

    		&:after {
    			display: none;
    		}
    	}

    	time {
    		display: block;
    		margin-bottom: 2px;
    	}

    	a.btn {
    		@extend .button;
    		// position: absolute;
    		// bottom: 0px;
    		// left: 0px;
    		// width: calc(100% - 20px);
    		float: left;
    	}

    	p {
    		font-size: em(18, 18);
    	}
    }
}

.listing--people-team {
	margin-top: 50px;
	padding: 0px 10px 30px 10px;
}

.listing--people {
	overflow: hidden;

	figure {
		float: left;
		margin-bottom: 10px;
		overflow: hidden;
		position: relative;

		&.team--small {
			width: 50%;

			&:nth-child(even){
				padding-left: 5px;
			}

			&:nth-child(odd){
				padding-right: 5px;
			}

			h3 {
				left: 19px;
				top: 16px;
			}

			&.team--5 {
				clear: left;
			}
		}

		&.team--large {
			cursor: pointer;
			width: 100%;

			&:after {
				background: url(../../dist/images/plus-white.svg) 0 0 / 30px 30px no-repeat;
				bottom: 30px;
				content: "";
				height: 30px;
				position: absolute;
				right: 30px;
				width: 30px;
				z-index: 1;
			}
		}

		span.image-wrap {
			overflow: hidden;
		    position: relative;
		    display: inline-block;
		    width: 100%;
		    z-index: 1;

			&:before {
				background: $text;
				bottom: 0px;
				content: "";
				left: 5px;
				opacity: 0;
				position: absolute;
				top: 0px;
				right: 5px;
				z-index: 2;
				@include trans(opacity);
			}
		}

		img {
			display: block;
			position: relative;
			z-index: 0;
		}

		h3 {
			color: #fff;
			font-size: em(20,20);
			font-weight: 700;
			left: 29px;
			position: absolute;
			top: 26px;
			z-index: 1;

			span {
				font-weight: 400;
			}
		}

		&:hover {

			span.image-wrap:before {
				opacity: .2;
			}

			img {
				//opacity: .4;
		        -webkit-filter:blur(2px);
		        filter: blur(2px);
			}

			h3 span {
				opacity: 1;
			}
		}

		&.inactive {

			span.image-wrap:before {
				opacity: .4;
			}

			img {
				//opacity: .4;
		        -webkit-filter:blur(2px);
		        filter: blur(2px);
			}

			h3 {
				opacity: 0;
			}

	        &:after {
	        	opacity: 0!important;
	        }
		}

		&.active {

			h3 span {
				opacity: 1;
			}

	        &:after {
	        	opacity: 0!important;
	        }

			&:hover {

				span.image-wrap:before {
					opacity: 0;
				}

		        img {
		        	-webkit-filter:blur(0px);
		        	filter: blur(0px);
		        }

		        &:after {
		        	opacity: 0!important;
		        }
			}
		}
	}

	.team-bio {
		display: none;
		//float: left;
		margin: 0 auto;
		max-width: 860px;
		overflow: hidden;
		padding: 20px 0;
		position: relative;
	    width: 100%;

		a.close {
			background: url(../../dist/images/+.svg) 0 0 / 30px 30px no-repeat;
			height: 36px;
			position: absolute;
			right: 0px;
			top: 20px;
			width: 36px;
			@include rotate(45deg);
		}

		h4 {
			font-size: em(30, 20);
			font-weight: 700;
			margin-bottom: 0px;
		}
		
		h5 {
			font-size: em(20, 20);
			font-weight: 400;
		}
	}
}

.post-listing {
	overflow: hidden;
	width: 100%;

	ul {
		li {
			clear: both;
			display: inline-block;
			margin-bottom: 20px;

			img {
				display: block;
				height: auto;
				margin-bottom: 20px;
				width: 100%;
			}

			h2 {
				font-weight: 700;
				font-size: 20px;

				a {
					text-decoration: none;
				}
			}

			time {
				display: block;
				font-size: 15px;
				margin-bottom: 30px;
			}

			p {
				display: block;
				font-size: 15px;
				line-height: em(28, 20);
				font-weight: 400;
				margin-bottom: em(35, 20);
				font-family: $font-family;
			}
		}
	}
}

@include media(small-tablet){
	.listing--news {

	    li {
	    	padding: 0px;
	    	width: 25%;

	    	&:nth-child(odd){
	    		padding-right: 20px;
	    	}

	    	&:nth-child(even){
	    		padding-left: 0px;
	    		padding-right: 20px;
	    	}

	    	&:nth-child(2n+1){
	    		clear: none;
	    	}

	    	&:nth-child(4n+1){
	    		clear: left;
	    	}
	    }
	}

	.listing--people-team {
		padding-left: 5px;
		padding-right: 5px;

		figure {

			&.team--small {
				width: 25%;

				&:nth-child(odd){
					padding-left: 5px;
					padding-right: 5px;
				}

				&:nth-child(even){
					padding-left: 5px;
					padding-right: 5px;
				}

				h3 {
					left: 29px;
					top: 26px;
				}
			}

			&.team--large {
				width: 50%;

				&:nth-child(odd){
					padding-left: 5px;
					padding-right: 5px;
				}

				&:nth-child(even){
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
	}

	.post-listing {

		ul {
			li {

				img {
					float: left;
					margin-right: 20px;
					width: 200px;
				}

				div.content-wrap {
					float: left;
					width: calc(100% - 220px);		
				}
			}
		}
	}
}

@include media(desktop){

	.listing--people-team {
		padding-left: 10px;
		padding-right: 10px;

		figure {
			margin-bottom: 20px;

			&.team--small {
				width: 20%;

				&:nth-child(odd){
					padding-left: 10px;
					padding-right: 10px;
				}

				&:nth-child(even){
					padding-left: 10px;
					padding-right: 10px;
				}
			}

			&.team--large {
				width: 25%;

				&:nth-child(odd){
					padding-left: 10px;
					padding-right: 10px;
				}

				&:nth-child(even){
					padding-left: 10px;
					padding-right: 10px;
				}

				&:after {
					opacity: 0;
					@include trans(opacity);
				}
			}

			h3 span {
				opacity: 0;
				@include trans(opacity);
			}

			&:hover {
				h3 span {
					opacity: 1;
				}

				&.team--large {
				
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
}


