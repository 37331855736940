// Base layout

.mobile-slide {
	
}

.container {
	@include clearfix();
	padding: 0 20px;
	margin: 0 auto;
	max-width: 1400px;

	&.container--home {
		background: #fff;
		max-width: 100%;
		position:relative;
		z-index: 1;
	}
}

.container--padded {
	padding-top: 60px;
	padding-bottom: 30px;
}

.col {
	float: left;
	width: 100%;
}

.col--flush {
	padding: 0;
}

.small-screen,
.small-med-screen {
	display: block;
}

.large-screen,
.larger-screen {
	display: none;
}

@include media('smallest') {

	.col--flush {
		float: left;

		&.col-4 {
			width: 33.333333333%;
		}
	}

	footer {
		.col-6 {
			width: 50%
		}
	}
}

@include media('small-tablet') {

	.col {
		padding: 0 10px;
	}

	.col--flush {
		padding: 0;
	}

	.col-1 {
		width: 8.333333333%;
	}

	.col-2 {
		width: 16.666666666%
	}

	.col-3 {
		width: 25%;
	}

	.col-4 {
		width: 33.333333333%
	}

	.col-5 {
		width: 41.666666667%;
	}

	.col-6 {
		width: 50%
	}

	.col-6--fixed {
		//width: 530px;
		width: 75%;
		margin: 0 auto;
		float: none;
	}

	.col-7 {
		width: 58.333333333%
	}

	.col-8 {
		width: 66.666666666%
	}

	.col-9,
	.col-9--over {
		width: 75%;
	}

	.col-10 {
		width: 83.333333333%;
	}

	.col-11 {
		width: 91.666666666%;
	}

	.col-12 {
		width: 100%;
		clear: both;
	}

	// Prefixes

	.pre-1 {
		margin-left: 8.333333333%;
	}

	.pre-2 {
		margin-left: 16.666666666%;
	}

	.pre-3 {
		margin-left: 25%;
	}

	.pre-4 {
		margin-left: 33.333333333%
	}

	.pre-5 {
		margin-left: 41.666666667%;
	}

	.pre-6 {
		margin-left: 50%
	}

	.pre-7 {
		margin-left: 58.333333333%
	}

	.pre-8 {
		margin-left: 66.666666666%
	}

	.pre-9 {
		margin-left: 75%;
	}

	.pre-10 {
		margin-left: 83.333333333%;
	}

	.pre-11 {
		margin-left: 91.666666666%;
	}

	// Suffixes

	.suf-1 {
		margin-right: 8.333333333%;
	}

	.suf-2 {
		margin-right: 16.666666666%;
	}

	.suf-3 {
		margin-right: 25%;
	}

	.suf-4 {
		margin-right: 33.333333333%
	}

	.suf-5 {
		margin-right: 41.666666667%;
	}

	.suf-6 {
		margin-right: 50%
	}

	.suf-7 {
		margin-right: 58.333333333%
	}

	.suf-8 {
		margin-right: 66.666666666%
	}

	.suf-9 {
		margin-right: 75%;
	}

	.suf-10 {
		margin-right: 83.333333333%;
	}

	.suf-11 {
		margin-right: 91.666666666%;
	}
}

@include media('tablet') {
	.small-screen {
		display: none;
	}

	.large-screen {
		display: block;
	}

	.col-7 {
		width: 58.333333333%
	}
}

@include media('desktop') {
	.col-6--fixed {
		width: 860px;
		margin: 0 auto;
		float: none;
	}

	.small-med-screen {
		display: none;
	}
	
	.larger-screen {
		display: block;
	}
}