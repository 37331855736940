.testimonial {
	overflow: auto;
}

.testimonial-iframe {

	iframe {
		width: 100%;
		display: block;
	}

	img {
		display: block;
	}
}

.testimonial-main {
	position: relative;
	padding: 40px;
	background-size: cover;

	&:before {
		content: "";
		background-color: $black;
		opacity: 0.3;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	p {
		color: $white;
		text-align: center;
		position: relative;
		font-weight: 700;
		text-transform: uppercase;
		font-size: em(24);
		line-height: em(33, 24);
		margin: 0;
	}
}

.testimonial--naked {
	text-align: center;

	&:nth-child(1) {
		margin-bottom: 30px;
	}

	p {
		font-weight: 700;
		font-size: em(24);
		line-height: em(33, 24);
		margin-bottom: 15px;
	}
}

.testimonial-footer {
	font-size: em(20);
	line-height: em(33, 20);
}

.testimonial--naked-container {
	padding-top: 60px;
	padding-bottom: 60px;
	@include clearfix();
}

@include media("mobile") {

	.testimonial {
		display: flex;
		align-items: stretch;
	}

	.testimonial-main {
		padding: 20px 40px;

		p {
			position: absolute;
			left: 40px;
			right: 40px;
			top: 50%;
			transform: translateY(-50%);
			font-size: em(19);
			line-height: em(24, 19);
		}
	}
}

@include media('tablet') {

	.testimonial--naked {
		width: 50%;
		float: left;

		&:nth-child(1) {
			padding-right: 60px;
		}

		&:nth-child(2) {
			padding-left: 60px;
		}
	}
}

@include media("desktop") {

	.testimonial-main {

		p {
			font-size: em(24);
			line-height: em(33, 24);
		}
	}
}