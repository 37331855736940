.carousel--position-wrapper {
	//padding-bottom: 160px;
    width: 100%;
}

img.placeholder {
	height: auto;
	max-height: 654px;
}

.carousel--position {
	position: fixed;
	top: 87px;
	width: 100%;
	@include trans(top);
}

.carousel {
	clear: both;
	position: relative;
	margin-bottom: 0px;

	img {
		display: block;
		float: left;
	}
}

.carousel--margins {
	.owl-item,
	.slick-slide {
		padding: 0 10px;
	}
}

.carousel-caption {
	transition: opacity 0.3s linear;
	background-color: $white;
	text-align: center;
	margin: 20px;
	display: block;
	padding: 15px;
	position: relative;

	&:before {
		content: "";
		width: 0; 
		height: 0; 
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid $white;
		position: absolute;
		top: -10px;
	}

	p {
		margin: 0;
	}
}

	
.owl-controls {
	height: 24px;
	width: 100%;
	margin: 0 auto;
	display: block;
	margin-top: -42px;
	position: absolute;
	top: 50%;
}

.owl-prev,
.owl-next {
	background: url('../../dist/images/arrow-right.svg') center center / 13px 24px no-repeat;
	width: 13px;
	height: 24px;
	font-size: 0;
	position: absolute;
}

.owl-prev {
	left: 20px;
	transform: rotate(180deg);
	@include trans(left);

	&:hover {
		left: 15px;
	}
}

.owl-next {
	right: 20px;
	@include trans(right);

	&:hover {
		right: 15px;
	}
}

.carousel-item {

	img {
	}

	a {
		color: $text;
		font-weight: 400;
	}

	h3 {
		color: $text;
		//bottom: -40px;
		font-weight: 400;
		margin-bottom: 0px;
		//position: absolute;
		text-align: center;
		width: 100%;
	}
}

.slick-track {
    position: relative;
    z-index: 0;
}

.slick-next,
.slick-prev {
	background: url('../../dist/images/arrow-right.svg') center center / 35px 66px no-repeat;
    border: none;
	font-size: 0;
	height: 24px;
	position: absolute;
	top: 50%;
	width: 13px;
    z-index: 1;
}

.slick-prev {
	left: 20px;
	transform: rotate(180deg);
}

.slick-next {
	right: 20px;
}

.carousel--2 {
	max-height: 795px;
	overflow: hidden;

	.carousel-item {
		opacity: 1;
	}

	.owl-controls {
		top: 50%;
		margin-top: -33px;
		position: absolute;
		width: 100%;
	}

	.owl-prev,
	.owl-next,
	.slick-next,
	.slick-prev {
		background: url('../../dist/images/arrow-right.svg') center center / 35px 66px no-repeat;
		height: 66px;
		width: 35px;
	}

	.owl-prev,
	.slick-prev {
		left: 50px;
		@include trans(left);

		&:hover {
			left: 30px;
		}
	}

	.owl-next,
	.slick-next {
		right: 50px;
		@include trans(right);

		&:hover {
			right: 30px;
		}
	}

	.slick-prev,
	.slick-next {
		margin-top: -33px;
	}
}

.carousel-wrapper--related {
	overflow: hidden;
}

.carousel-related-slick {
	overflow: hidden;

	.carousel-item {
		float: left;
		opacity: 1;
	}

	.slick-prev,
	.slick-next {
		background: url('../../dist/images/arrow-right.svg') center center / 13px 24px no-repeat;
		width: 13px;
		height: 24px;
		font-size: 0;
		position: absolute;
		top: 50%;
		margin-top: -43px;
	}

	.slick-prev {
		left: 20px;
		transform: rotate(180deg);
		@include trans(left);

		&:hover {
			left: 15px;
		}
	}

	.slick-next {
		right: 20px;
		@include trans(right);

		&:hover {
			right: 15px;
		}
	}
}
	
@media screen and (max-width: 640px){
	.scrolling {
		.carousel--position {
			top: 72px;
		}
	}
}

@include media('small-tablet') {

	// .carousel--position-wrapper {
	// 	padding-bottom: 395px;
	// }
}

@include media('tablet') {

	// .carousel--position-wrapper {
	// 	padding-bottom: 490px;
	// }

	.carousel--position {
		
	}

	.carousel-item {
		opacity: 0.5;
		transition: opacity 0.3s linear;

		&.active {
			opacity: 1;

			.carousel-caption {
				opacity: 1;
			}
		}
	}

	.carousel-caption {
		opacity: 0;
		margin: 20px 0 0 0;
	}



	.carousel--2 {

		.carousel-item {
			opacity: 1;
		}
	}
}

@include media('desktop') {

	// .carousel--position-wrapper {
	// 	padding-bottom: 559px;
	// }
}