%svg-common {
	background: url("svg/sprite.css.svg") no-repeat;
}

.svg--icon-+ {
	@extend %svg-common;
	background-position: 99.52632993761645% 66.06971604886795%;
}

.svg--icon-+-dims {
	width: 27.238px;
	height: 27.238px;
}

.svg--icon-Facebook {
	@extend %svg-common;
	background-position: 65.46644844517185% 68.19815360778153%;
}

.svg--icon-Facebook-dims {
	width: 41.37px;
	height: 37.717px;
}

.svg--icon-ITF-Logo-White {
	@extend %svg-common;
	background-position: 56.263207133651285% 41.107880077332474%;
}

.svg--icon-ITF-Logo-White-dims {
	width: 90.147px;
	height: 49.031px;
}

.svg--icon-LinkedIn {
	@extend %svg-common;
	background-position: 42.65238307791499% 82.3396857505618%;
}

.svg--icon-LinkedIn-dims {
	width: 41.37px;
	height: 37.717px;
}

.svg--icon-List_icon {
	@extend %svg-common;
	background-position: 89.85028947435538% 38.30613207967163%;
}

.svg--icon-List_icon-dims {
	width: 46.955px;
	height: 26.067px;
}

.svg--icon-Logo-red {
	@extend %svg-common;
	background-position: 0 0;
}

.svg--icon-Logo-red-dims {
	width: 242.188px;
	height: 64.533px;
}

.svg--icon-O {
	@extend %svg-common;
	background-position: 43.65482233502538% 69.98012618296531%;
}

.svg--icon-O-dims {
	width: 46px;
	height: 46px;
}

.svg--icon-Oxford-logo-white {
	@extend %svg-common;
	background-position: 0 21.621485792399163%;
}

.svg--icon-Oxford-logo-white-dims {
	width: 242.188px;
	height: 64.533px;
}

.svg--icon-Twitter {
	@extend %svg-common;
	background-position: 85.98422853742002% 68.19815360778153%;
}

.svg--icon-Twitter-dims {
	width: 41.37px;
	height: 37.717px;
}

.svg--icon-X-white {
	@extend %svg-common;
	background-position: 98.62082379255774% 37.278393631831605%;
}

.svg--icon-X-white-dims {
	width: 16.778px;
	height: 16.778px;
}

.svg--icon-arrow-white {
	@extend %svg-common;
	background-position: 97.2736057821515% 54.632322265577066%;
}

.svg--icon-arrow-white-dims {
	width: 68.117px;
	height: 37.008px;
}

.svg--icon-grid_icon {
	@extend %svg-common;
	background-position: 60.08642406287445% 80.19071856287424%;
}

.svg--icon-grid_icon-dims {
	width: 31.022px;
	height: 29px;
}

.svg--icon-pact_logo {
	@extend %svg-common;
	background-position: 54.12719891745603% 55.7843137254902%;
}

.svg--icon-pact_logo-dims {
	width: 84.115px;
	height: 43.74px;
}

.svg--icon-red_arrow {
	@extend %svg-common;
	background-position: 83.67031963470319% 79.0079646017699%;
}

.svg--icon-red_arrow-dims {
	width: 24px;
	height: 24px;
}

.svg--icon-red_magnifyingglass {
	@extend %svg-common;
	background-position: 94.62922374429223% 79.0079646017699%;
}

.svg--icon-red_magnifyingglass-dims {
	width: 24px;
	height: 24px;
}

.svg--icon-viewfinder_corner {
	@extend %svg-common;
	background-position: 72.60559054612796% 79.20562820490072%;
}

.svg--icon-viewfinder_corner-dims {
	width: 24.846px;
	height: 24.846px;
}

.svg--icon-“ {
	@extend %svg-common;
	background-position: 0 99.28153846153846%;
}

.svg--icon-“-dims {
	width: 86px;
	height: 233px;
}

