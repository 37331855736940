.footer {
	color: $white;
	padding-top: 10px;
	background-color: $text;
	overflow: hidden;
	padding: 28px 8px;
	position: relative;
	text-align: center;

	.col-3 {
		margin-bottom: 40px;
	}

	.icon {
		position: relative;
		top: 3px;
		margin-right: 5px;

		svg {
			height: 14px;
			width: 14px;
		}
	}

	a {
		svg {
			fill:#c32020;
			@include trans(fill);
		}

		&:hover {
			svg {
				fill: #fff;
			}
		}
	}

	.btn-signup {
		background: $feature;
		color: $white;
		display: inline-block;
		font-size: 16px;
		font-weight: 700;
		padding: 18px 45px;
		margin: 35px 0 25px;
		@include trans(all);

		&:hover {
			background: $white;
			color: $feature;
		}
	}

	ul.logos {
		overflow: hidden;
		margin: 0 auto 30px auto;
		width: 50%;

		li {
			float: left;
			width: 50%;

			&.logo1 {
				img {
					height: 61px;
					width: 116px;
				}
			}

			&.logo2 {
				img {
					height: 42px;
					width: 99px;
				}
			}
		}
	}

	.credits {
		font-size: 12px;
		text-align: left;

		a {
			color: $white;
			text-transform: uppercase;

			&:hover {
				color: $feature;
			}

			&.btn-signup {
				text-align: center;
				text-transform: none;
			}
		}

		p {
			line-height: 1.2;
			margin-bottom: 0px;
			
			a {
				text-transform: none;
			}
		}

		ul {
			li {
				margin-bottom: 3px;
			}
		}
	}
}

@include media('smallest') {

	.footer {

		.col-3 {
			width: 50%;

			&:nth-child(3n) {
				clear: left;
			}
		}

		.credits {
			width: 100%;
		}
	}
}

@include media('tablet') {

	.footer {

		ul.logos {
			float: right;
			max-width: 250px;
			width: 100%;

			li {
				text-align: right;

				&.logo2 {
					padding-top: 10px;
				}
			}
		}

		.credits {
			font-size: 14px;
			width: 33.33%;

			ul {
				li {
					font-size: 16px;
					margin-bottom: 4px;
				}
			}
		}
	}

	
}