.cross-sell {
	text-align: center;
	color: $white;
	padding: em(15);
	margin-bottom: 30px;

	.cross-sell-title {
		color: $white;
		font-weight: 400;
		font-size: em(30);
		line-height: em(30, 30);
		margin-bottom: em(10, 30);
	}

	.cross-sell-copy,
	p.cross-sell-copy {
		font-size: em(16);
		line-height: em(20, 16);
	}

	.cross-sell-link {
		font-size: em(20, 16);
		line-height: em(24, 20);
		font-weight: 600;
		display: block;
		text-decoration: none;
	}

	.cross-sell-icon {
		margin-bottom: 24px;
	}
}

.cross-sell-buttons {
	@include clearfix();


	.cross-sell-button-item {
		padding: 0 10px;
		margin-bottom: 20px;

		&:before {
			display: none;
		}

		text-align: center;
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
	}

	.cross-sell-button-link {
		border-radius: 20px;
		font-size: 16px;
		display: block;
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: center;
		font-weight: 700;
		color: $white;

		
	}

}

aside .cross-sell-buttons.cross-sell-buttons--aside .cross-sell-button-item {
	padding: 0 0px;
	width: 100%;

	a {
		margin-bottom: 13px;
		text-decoration: none;
	}
}


@include media('small-tablet') {

	.cross-sell-buttons {

		.cross-sell-button-item {
			padding: 0 20px;
		}
	}
}


@include media('tablet') {

	.cross-sell {
		text-align: left;
		@include clearfix();

		.button {
			float: right;
		}
	}

	.cross-sell-copy {
		float: left;
		position: relative;
		padding-left: 54px;
		margin-bottom: 0;

		&:before {
			content: "";
			display: block;
			background-image: url('../icons/svg/telephone.svg');
			width: 24px;
			height: 24px;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.cross-sell-icon {
		display: none;
	}

	.cross-sell-link {
		display: inline;
		margin-right: 10px;
	}

	.cross-sell-title {
		margin-bottom: 20px;
	}

	.cross-sell-buttons {

		.cross-sell-button-item {
			float: left;
			width: 33.333333333%;
		}
	}
}

@include media('desktop') {

	.cross-sell {
		text-align: center;

		.button {
			float: none;
		}
	}

	.cross-sell-copy {
		float: none;
		padding: 0;
		margin-bottom: 28px;

		&:before {
			display: none;
		}
	}

	.cross-sell-icon {
		display: inline;
		margin-bottom: 45px;
	}

	.cross-sell--tight {

		.cross-sell-icon {
			margin-bottom: 12px;		
		}
	}

	.cross-sell-link {
		display: block;
		margin-right: 0;
	}

	.cross-sell-title {
		margin-bottom: 10px;
	}

	.full-width {
		&.cross-sell {
			text-align: left;
			@include clearfix();

			.button {
				float: right;
			}
		}

		.cross-sell-copy {
			float: left;
			position: relative;
			padding-left: 54px;
			margin-bottom: 0;

			&:before {
				content: "";
				display: block;
				background-image: url('../icons/svg/telephone.svg');
				width: 24px;
				height: 24px;
				position: absolute;
				left: 0;
				top: 0;
			}
		}

		.cross-sell-icon {
			display: none;
		}

		.cross-sell-link {
			display: inline;
			margin-right: 10px;
		}

		.cross-sell-title {
			margin-bottom: 20px;
		}
	}
}