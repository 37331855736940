// Header

.header {
	background: $white;
	z-index: 10;
	padding-top: 18px;
	padding-bottom: 15px;
    position: relative;
	//@include trans(all);

	// &.scrolling {
	// 	padding-top: 0px;
	// 	padding-bottom: 0px;
	// }
}

.stuck {
	padding-top: 75px;
}

.contact .header-title-container {
	margin-bottom: 0px;
}

.header-title-container,
.header-title-container--sub {
	background-color: $background;
	height: 65px;
	margin-bottom: 30px;
	padding-bottom: 25px;
	position: relative;
	z-index: 1;

	h2 {
		&:after {
			display: none;
		}
	}

	&.stick {
		position: fixed;
		top: 8px;
		left: 0px;
		width: 100%;
		z-index: 100;
	}
}

.page-template-page-about .header-title-container {
	margin-bottom: 0px;
}

.header-title {
	color: $text;
	display: inline-block;
	font-size: em(20);
	line-height: 1em;
	font-weight: 700;
	font-family: $font-family;
	clear: both;
	padding-top: 20px;
	text-align: left;

	a {
		color: $text;
		padding-right: 22px;
		position: relative;

		&:after {
			content: '';
			background: url('../../dist/images/black_arrow.svg') right top / 12px 7px no-repeat;
			width: 12px;
			height: 7px;
			display: inline-block;
		    position: absolute;
		    top: 11px;
		    right: 0px;
		    @include trans(transform);
		}

		&.open {
			&:after {
				transform: rotate(180deg);
			}
		}
	}
}

.header-subtitle {
	color: $white;
	font-size: em(20);
	line-height: em(30, 20);
	text-align: center;
	position: absolute;
	width: 100%;
}

.logo-container {
	position: relative;
	z-index: 100;
}

.logo {
	background: url("../images/oxford-films-logo.svg") no-repeat;
	background-size: auto 100%;
	display: block;
	height: 43px;
    width: 152px;
}

header {

	.menu-container {
		position: static;
	}

	.menu-toggle {
		cursor: pointer;
		width: 22px;
		height: 17px;
		float: right;
		padding: 7px 0 0 0;
		position: relative;
		top: 12px;
		transition: all 0.2s linear;
		z-index: 100;

		&.open .menu-toggle-btn {
			background-color: transparent;

			&:before {
				transform: rotate(45deg);
				top: 5px;
			}

			&:after {
				transform: rotate(-45deg);
				bottom: 9px;
			}
		}
	}

	.menu-toggle-btn {
		width: 22px;
		height: 3px;
		background-color: $feature;
		display: block;
		border-radius: 0px;

		&:before,
		&:after {
			content: "";
			position: absolute;
			width: 22px;
			height: 3px;
			left: 0;
			background-color: $feature;
			transition: all 0.2s linear;
			border-radius: 2px;
		}

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
		}
	}

	.menu {
		width: 100%;
		padding: 10px;
		@include clearfix();
		height: 40px;
	}

	.menu-item {

		a {
			color: $text;
			text-decoration: none;
			font-size: em(22.5);
			line-height: 1em;
			display: block;
			padding: 8px 30px 8px;
			font-family: $font-family-2;
			font-weight: 700;
			cursor: pointer;
		}

		&.menu-home {
			// position: relative;
			// top: -20px;
			// margin-right: 10px;
			padding: 8px 0;

			a {
				font-size: 0;
				background: url('../../src/icons/home-icon.svg') center center / 43px 44px no-repeat;
				display: block;
				width: 43px;
				height: 44px;
				margin: 0 auto;
			}
		}
	}

	.menu-item-has-children {

		// > a {
		// 	display: none;
		// }

		ul {
			display: none;
		}
	}

	.sub-menu {
		position: relative;
		overflow: hidden;
		display: block;

		.menu-item {
			

			a {
				position: relative;
				transition: all 0.2s linear;
				font-size: em(22.5);
				line-height: 1em;
				color: $white;
			}

			.sub-menu {

				.menu-item {

					a {

						&:before {
							background-image: none;
							width: 5px;
							height: 5px;
							transform: rotate(45deg);
							top: 28px;
							background-color: $white;
							left: 45px;
						}
					}
				}
			}
		}
	}

}

.utility {
	display: block;
}

.utility-container {
	display: none;
}

.home {
	.mobile-nav.open {
		position: absolute;
	    top: 70px;
	    width: 100%;
	    z-index: 100;
	}
}

// .utility-container {
// 	position: fixed;
// 	overflow: hidden;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	height: 0;
// 	background-color: $blue;
// 	background: $blue_gradient_transparent;
// 	z-index: 99;
// 	text-align: center;
// 	transition: all 0.3s ease-in;
// 	min-height: 0;

// 	a {
// 		color: $text;
// 	}

// 	&.open {
// 		min-height: 100%;
// 		overflow: scroll;
// 		padding: 100px 20px 20px;
// 	}

// 	&.is-centred {
// 		display: flex;
// 		align-items: center;
// 	}
// }

.utility-list {
	margin: 0 auto;
}

.logo-container {
	padding: 0;
	float: left;
}

.header-subtitle,
header .search {
	display: none;
}

.mobile-nav {
	background: $feature;
	display: none;
    position: relative;
	text-align: center;
    z-index: 999;

	ul {
		margin-top: 45px;

		li {
			padding-bottom: 45px;

			a {
				color: $white;
				font-size: em(20);
				font-weight: 700;
			}

			ul {
				display: none;
			}
		}
	}
}

.listing-options {
	display: none;
	float: right;
	margin-top: 23px;

	li {
		float: left;
		margin-left: 28px;

		a {
			background-repeat: no-repeat;
			background-size: 100% auto;
			display: block;
			opacity: .5;
			@include trans(opacity);

			&:hover,
			&.active {
				opacity: 1;
			}
		}

		.listing-options--grid {
			background-image: url(../../dist/images/grid_icon.svg);
			height: 29px;
			width: 32px;
		}

		.listing-options--list {
			background-image: url(../../dist/images/List_icon.svg);
			height: 31px;
			width: 45px;
		}
	}
}

body.single-project .header-subtitle {
	display: block;
}

body.scrolling {

	.header-title-container {
		position: fixed;
		top: 8px;
		left: 0px;
		width: 100%;
		z-index: 100;
	}
}

@include media('small-tablet') {

	.menu {
		padding: 10px 20px;
	}
}

@include media('desktop') {

	.listing-options {
		display: block;
	}
	
	.post-type-archive-work #menu-item-111 a {
		color: $feature;
	}

	.header-title-container,
	.header-title-container--sub {
		height: 75px;
		margin-bottom: 0px;
	}

	header .search {
		background: url("../images/search.svg") no-repeat;
		display: block;
		float: left;
		height: 18px;
		margin: 4px 0 0 55px;
		width: 18px;
	}

	.logo {
		height: 65px;
	    width: 231px;
	}

	header.header {
		//position: fixed;
		//left: 0;
		//right: 0;
		//top: 0;
		padding-bottom: 33px;
		padding-top: 32px;

		.menu-container {
			position: relative;
		}

		.menu-toggle {
			display: none;
		}

		.menu {
			position: relative;
			min-height: 0;
			padding: 0;
			z-index: 10;
		}

		.utility {
			position: absolute;
			right: 20px;
			top: 8px;
			transition: all 0.3s ease-in;

			li {
				float: left;
				margin-left: 58px;

				a {
					font-family: $font-family;
					font-size: em(20, 18);
					font-weight: 700;
					text-decoration: none;

					&:hover {
						color: $feature;
					}
				}
			}	

			.utility-list {
				float: left;

				> li {
					height: 90px;
				}
			}	
		}

		.menu-item {
			float: left;

			a {
				padding: 0;
				transition: all 0.2s linear;
				font-size: em(18);
				line-height: em(22, 18);
			}

			&.current-menu-item,
			&.current-page-ancestor {
				a {
					color: $feature;
				}
			}

			&.menu-home {
				position: relative;
				top: -20px;
				margin-right: 10px;
				padding: 0;

				a {
					font-size: 0!important;
					background: url('../../src/icons/home-icon.svg') center center / 19px 20px no-repeat;
					display: block;
					width: 19px;
					height: 20px;
				}
			}
		}

		.menu-item-has-children {
			margin-right: -22px;

			> a {
				display: block;
				padding-right: 22px;

				&:after {
					content: '';
					background: url('../../dist/images/red_arrow.svg') right top / 12px 7px no-repeat;
					width: 12px;
					height: 7px;
					display: inline-block;
				    position: absolute;
				    top: 10px;
				    right: 0px;
				}
			}
		}

		.sub-menu {
			display: none;
			position: absolute;
			top: 90px;
			background-color: $white;
			overflow: visible;
			padding: 0px 50px 00px 30px;
			z-index: 99;

			.menu-item {
				float: none;
				display: block;
				clear: both;
				margin-bottom: 20px;
				margin-left: 0px;
				text-align: left;

				a {
					color: $text;
					font-weight: 400;
					margin: 0;
					border: none;
					font-size: em(16, 18);
					line-height: em(18, 12);

					&:hover {
						color: $feature;
						font-weight: 700;
					}
				}

				> ul li.current-menu-item a {
					font-weight: 700;
				}
			}

			.menu-item-has-children {

				a {

					&:after {
						display: none;
					}
				}
			}

			.sub-menu {
				display: none !important; // override JS
			}

			.menu-item-has-children {

				> a {

					.menu-item-arrow {
						display: none;
					}
				}
			}
		}

		.menu-item-has-children {
		
			> a {
				position: relative;
			}
		}
	}

	.utility-container {
		position: static;
		background: none;
		display: block;
		height: auto;
		overflow: visible;
	}

	.utility {
		background: none;
		display: block;
		text-align: right;
		color: $white;
		padding-left: 0;
		transition: all 0.2s linear;
		float: right;
	}

	.utility-list {
		@include clearfix();
		text-align: right;
		transition: all 0.2s linear;
		position: relative;

		> .menu-item {
			display: inline-block;
			float: none;
			margin-left: 24px;

			&:nth-child(1) {
				margin-left: 0;
			}

			> a {
				font-size: 20px;
				//padding-bottom: 25px;
			}

			a {
				text-decoration: none;
				transition: all 0.2s linear;
				font-size: em(14);
				line-height: em(20, 14);
				margin-right: 0px;

				&:hover {
					text-decoration: none;
				}
			}

			&.current-menu-item {
				a {
					text-decoration: none;
				}
			}
		}
	}

	.header-title {
		font-size: em(30);
	}
}
