.breadcrumb,
aside ul.menu {
	padding: 0px;

	li {
		margin-bottom: 25px;

		ul {
			display: none;
			padding-top: 25px;

			a {
				padding-left: 15px;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					width: 5px;
					height: 5px;
					left: 0;
					top: 7px;
					transform: rotate(45deg);
					transition: all 0.2s linear;
				}
			}
		}

		&.current_page_item,
		&.current-menu-ancestor {
			> a {
				
			}

			ul.sub-menu {
				display: block;
			}
		}
	}

	a {
		text-decoration: none;
		transition: all 0.2s linear;
		text-decoration: none;
		cursor: pointer;
		display: inline-block;
	}

}