.card-wrapper {
	// padding-top: 45px;
	padding-bottom: 20px;
}

.card {
	color: $white;
	font-family: $font-family-2;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;

	.img-ratio {
		padding-bottom: 67%; /* 16:9 */
		width: 100%;

		img {
			position: absolute;
			top: 0px;
			left: 50%;
			height: 100%;
			width: auto;
			@include translateX;
		}
	}

	.mask {
		background: $white;
		height: 100%;
		position: absolute;
		top: 0px;
		width: 5px;
		z-index: 2;

		&.mask--1 {
			left: 0px;
		}

		&.mask--2 {
			right: 0px;
		}
	}

	&:before {
		background: $text;
		bottom: 0px;
		content: "";
		left: 5px;
		opacity: 0;
		position: absolute;
		top: 0px;
		right: 5px;
		z-index: 2;
		@include trans(opacity);
	}

	&:after {
		background: url(../images/gradient-half-height.png) repeat-x 0 0;
		background-size: 100% 50%;
		content: "";
		height: 100%;
		left: 5px;
		position: absolute;
		top: 0px;
		width: 100%;
		z-index: 1;
	}

	&:hover {
		color: $white;

		&:before {
			opacity: .2;
		}

		img {
			//opacity: .4;
	        -webkit-filter:blur(2px);
	        filter: blur(2px);
		}

		p,
		footer {
			opacity: 1;
		}
	}

	&.card--small {
		margin-bottom: 10px;
		padding: 0 5px;
		width: 50%;
	}

	&.card--medium {
		margin-bottom: 10px;
		padding: 0 5px;
		width: 100%;
	}

	&.card--large {
		margin-bottom: 10px;
		padding: 0 5px;
		width: 100%;
	}

	header {
		left: 21px;
		position: absolute;
		top: 11px;
		width: 100%;
		z-index: 3;

		h2 {
			font-size: em(16, 16);
			font-weight: 700;
			margin: 0px;
			padding: 0px;
			text-align: left;

			&:after {
				display: none;
			}
		}

		p {
			opacity: 0;
			@include trans(opacity);
		}
	}

	footer {
		bottom: 15px;
		font-size: em(16, 16);
		left: 21px;
		opacity: 0;
		position: absolute;
		width: 100%;
		z-index: 2;
		@include trans(opacity);
	}

	img {
		display: block;
		opacity: 1;
		position: relative;
		z-index: 1;
		@include trans(all);
	}
}

.card-sizer {
	padding: 0 10px;
}

.card--white {
	color: $greyDark;
	padding: 0 10px;

	&:hover,
	.card-title {
		color: $greyDark;
	}
}

.card-container {
	position: relative;
	height: 0;
	padding-bottom: 100%;

	&.is-open {

		.card-header .card-icon {
			opacity: 0;
		}
	}
}

.card-container--dynamic {
	height: auto;
	padding: 15px 24px;
	background-color: $white;
}

.card-copy--accordion {
	display: none;
}

.card-footer--dynamic {
	height: auto;
	position: static;
	padding: 24px 0 20px 0;
	@include clearfix();

	.icon {
		top: auto;
		margin-top: 0;
		position: static;
		right: auto;
	}
}

.card-icon--left {
	float: left;
}

.card-icon--right {
	float: right;
}

.card-date {
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	left: 0;
}

.card--note {

	.card-header {

		&:after {
			content: "";
			background: url('../../dist/icons/svg/notebook-icon-white.svg') center center / 20px 20px no-repeat;
			width: 20px;
			height: 20px;
			display: inline-block;
		}
	}

	.card-footer {
		border-top: 1px solid $white;
	}
}

.card--featured {

	.card-header {
		position: relative;

		&:after {
			content: "";
			background: url('../../dist/icons/svg/pin.svg') center center / 26px 26px $white no-repeat;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			display: inline-block;
			position: absolute;
			right: -30px;
			top: -30px;
			z-index: 1;
		}
	}
}

.awards {
	padding-bottom: 50px;

	img {
		display: block;
	}
}

.list-wrapper {
	div.row {
    	display: inline-block;
		padding-bottom: 20px;
		padding-top: 20px;
		width: 100%;
	}

	div.row--header {
		display: none;
	}

	div.row--awards {
		padding-left: 30px;
		padding-right: 30px;

		p {
			overflow: hidden;

			span {
				float: left;
				line-height: 1.2;
				margin-right: 10px;

				&.col1 {
					width: auto;
				}
				&.col2 {
					width: auto;
				}
				&.col3 {
					width: auto;
				}
				&.col4 {
					clear: left;
					width: auto;
				}
				&.col5 {
					width: auto;
				}
			}
		}
	}

	div.row--hover {

		&:hover {
			background: rgba(195,32,32,0.2);
		}
	}

	p {
		font-size: em(20, 18);
		line-height: 1;
		margin: 0 auto;
		max-width: 840px;
		width: 100%;

		a {
			color: $text;
			overflow: hidden;
		}

		span {
			display: inline-block;
			float: left;
			vertical-align: top;

			&.col1 {
				width: 10%;
			}
			&.col2 {
				width: 42%;
			}
			&.col3 {
				width: 35%;
			}
			&.col4 {
				width: 13%;
			}
		}
	}
}

.hidden {
	display: none;
}

@include media('tablet') {

	.list-wrapper {
		div.row--header {
			display: block;
		}

		div.row--awards {

			p span {
				float: none;
				margin-right: 0px;
				padding-right: 30px;

				&.col1 {
					width: 10%;
				}
				&.col2 {
					width: 30%;
				}
				&.col3 {
					width: 20%;
				}
				&.col4 {
					width: 20%;
				}
				&.col5 {
					width: 20%;
				}
			}
		}
	}

	.card {

		.mask {
			width: 10px;
		}

		&:before,
		&:after {
			left: 10px;
			right: 10px;
		}

		&.card--small {
			margin-bottom: 20px;
			padding: 0 10px;
			width: 33.33%;
		}

		&.card--medium {
			margin-bottom: 20px;
			padding: 0 10px;
			width: 33.33%;
		}

		&.card--large {
			margin-bottom: 20px;
			padding: 0 10px;
			width: 50%;
		}
	}
}

@include media('desktop') {

	.list-wrapper {

		div.row--awards {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.card {

		&.card--small {
			margin-bottom: 20px;
			padding: 0 10px;
			width: 25%;
		}

		header {
			h2 {
				font-size: em(20,16);
			}
		}

		&.card--small,
		&.card--medium {
			header {
				left: 29px;
				top: 16px;
			}
			
			footer {
				bottom: 19px;
				left: 29px;
			}
		}

		&.card--large {
			header {
				left: 50px;
				top: 34px;
			}

			footer {
				bottom: 37px;
				left: 50px;
			}
		}
	}
}