.pagination {
	margin-top: 40px;
	text-align: center;
	width: 100%;

	.page-numbers {
		display: inline-block;
		width: 38px;
		height: 38px;
		color: $white;
		line-height: 38px;
		font-family: $font-family;
		background-color: $text;
		text-decoration: none;
		margin: 0 7px;
		margin-bottom: 40px;
		transition: all 0.2s linear;

		&:hover,
		&.current {
			background-color: $feature;
		}
	}

	.prev {
		display: none;
	}

	.next {
		display: none;
	}
	
}