// Clearfix
// -------------------------------------

.u-clearfix,
%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.u-hide {
	display: none;
}

.u-clear {
	clear: both;
}

// Justify children
// -------------------------------------

.u-justify-children {
	text-align: justify;

	&:after {
		content: "";
		width: 100%;
		display: inline-block;
	}

}

// Layout
// -------------------------------------

.u-centre {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0 auto;
}

.u-no-padding {
	padding: 0;
}

.u-margin-bottom--18 {
	margin-bottom: 18px;
}
// Text Alignment
// -------------------------------------

.u-align-centre {
	text-align: center;
}

.u-align-left {
	text-align: left;
}


.u-align-right {
	text-align: right;
}

.u-italic {
	font-style: italic;
}

.u-lowercase {
	text-transform: lowercase;
}

// Mobile Only
// -------------------------------------

.u-desktop-only,
.u-large-desktop-only,
.u-largest-only {
	display: none;
}


@include media("tablet") {

	.u-desktop-only {
		display: block;
	}

	.u-mobile-only {
		display: none;
	}
}

@include media("desktop") {
	
	.u-large-desktop-only {
		display: block;
	}

	.u-tablet-only {
		display: none;
	}
}

@include media("large-desktop") {
	
	.u-largest-only {
		display: block;
	}
}