.stagger {

}

// .stagger-row {
// 	display: flex;
// }

.stagger-col {
	// width: 50%;
	background-size: cover;
	background-position: center center;
	min-height: 300px;

	img {
		display: block;
	}
}

.stagger-container {
	padding: 90px 50px;
	max-width: 700px; // half of .container max-width
}

.stagger-title {

}

@include media('tablet') {

	.stagger-col {
		min-height: 500px;
	}
}

@include media('desktop') {

	.stagger-row {
		display: flex;

		&:nth-child(even){
			.stagger-col {

				&:nth-child(1) {
					order: 2;
				}

				&:nth-child(2) {
					order: 1;
				}
			}
		}
	}

	.stagger-col {
		width: 50%;
		min-height: 0;
	}

	.stagger-row--right {

	}
}