

.social-item {
	width: 42px;
    height: 38px;
	display: inline-block;
	margin-right: 10px;
}

a.social-item {

	.icon {
		transition: all 0.2s linear;
	}

	&:hover .icon {
		fill: $green;
	}
}

.social--share {
	float: left;

	.social-item {
		width: 38px;
		height: 38px;
		margin: 0 0 0 5px;
		display: block;
		float: right;

		&:nth-child(3) {
			margin-left: 0;
		}
	}

	a.social-item {

		.icon {
			transition: all 0.2s linear;
		}

		&:hover .icon {
			fill: #cccccc;
		}
	}
}

.social--inline {
	display: inline-block;
	float: none;
}

@include media('tablet') {

	.social {
		text-align: center;
	}

	.social--share {
		position: absolute;
		right: 0;
		top: 0;
	}

	.social--inline {
		position: static;
		top: auto;
		right: auto;
	}
}