/* Generated with Bounce.js. Edit at https://goo.gl/xNnDGq */
@-webkit-keyframes animation { 
  0% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4% { -webkit-transform: matrix3d(1.308, 0, 0, 0, 0, 1.308, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.308, 0, 0, 0, 0, 1.308, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  8.01% { -webkit-transform: matrix3d(1.375, 0, 0, 0, 0, 1.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.375, 0, 0, 0, 0, 1.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  31.03% { -webkit-transform: matrix3d(1.076, 0, 0, 0, 0, 1.076, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.076, 0, 0, 0, 0, 1.076, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  54.05% { -webkit-transform: matrix3d(1.005, 0, 0, 0, 0, 1.005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.005, 0, 0, 0, 0, 1.005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}

@keyframes animation { 
  0% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4% { -webkit-transform: matrix3d(1.308, 0, 0, 0, 0, 1.308, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.308, 0, 0, 0, 0, 1.308, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  8.01% { -webkit-transform: matrix3d(1.375, 0, 0, 0, 0, 1.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.375, 0, 0, 0, 0, 1.375, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  31.03% { -webkit-transform: matrix3d(1.076, 0, 0, 0, 0, 1.076, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.076, 0, 0, 0, 0, 1.076, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  54.05% { -webkit-transform: matrix3d(1.005, 0, 0, 0, 0, 1.005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.005, 0, 0, 0, 0, 1.005, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}

.overlay--cover {
	background: $feature;
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	z-index: 999999;
}

.home {

	.header {
		.logo {
			background: url("../images/oxford-films-logo-white.svg") no-repeat;
			background-size: 100% auto;
		}
	}

	.utility {
		z-index: 999999;

		li a {
			color: #fff;
		}
	}

	.carousel-outer-wrapper {
		&:after {
			background: url('../../dist/images/arrow-down.svg') center center / 66px 35px no-repeat;
			bottom: 40px;
			content: "";
			height: 66px;
			left: 50%;
			margin-left: -33px;
			opacity: 0;
			position: absolute;
			width: 66px;
			@include trans(all);
		}

		&:hover {
			&:after {
				bottom: 20px;
				opacity: 1;
			}
		}
	}

	.carousel {
		position: fixed;
	    top: 0px;
	    width: 100%;
	    height: 100%;
	    left: 0px;
	    z-index: 0;

	    p {
	    	position: absolute;
		    bottom: 80px;
		    left: 25px;
		    z-index: 1;
		    color: #fff;
		    margin-bottom: 0px;
		    font-size: 35px;
		    font-weight: 700;
		    line-height: 1.1em;
	    }

	    .slick-arrow {
	    	display: none!important;
	    }
	}

	/* Slider */
	.slick-slider
	{
	    // position: relative;

	    // display: block;
	    // box-sizing: border-box;

	    // -webkit-user-select: none;
	    //    -moz-user-select: none;
	    //     -ms-user-select: none;
	    //         user-select: none;

	    // -webkit-touch-callout: none;
	    // -khtml-user-select: none;
	    // -ms-touch-action: pan-y;
	    //     touch-action: pan-y;
	    // -webkit-tap-highlight-color: transparent;

	}

	.slick-list
	{
	    position: relative;

	    display: block;
	    overflow: hidden;

	    margin: 0;
	    padding: 0;
	}
	.slick-list:focus
	{
	    outline: none;
	}
	.slick-list.dragging
	{
	    cursor: pointer;
	    cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list
	{
	    -webkit-transform: translate3d(0, 0, 0);
	       -moz-transform: translate3d(0, 0, 0);
	        -ms-transform: translate3d(0, 0, 0);
	         -o-transform: translate3d(0, 0, 0);
	            transform: translate3d(0, 0, 0);
	}

	.slick-track
	{
	    position: relative;
	    top: 0;
	    left: 0;

	    display: block;
	}
	.slick-track:before,
	.slick-track:after
	{
	    display: table;

	    content: '';
	}
	.slick-track:after
	{
	    clear: both;
	}
	.slick-loading .slick-track
	{
	    visibility: hidden;
	}

	.slick-slide
	{
	    display: none;
	    float: left;

	    height: 100%;
	    min-height: 1px;
	    background-size: cover;
	}
	[dir='rtl'] .slick-slide
	{
	    float: right;
	}
	.slick-slide img
	{
	    display: block;
	}
	.slick-slide.slick-loading img
	{
	    display: none;
	}
	.slick-slide.dragging img
	{
	    pointer-events: none;
	}
	.slick-initialized .slick-slide
	{
	    display: block;
	}
	.slick-loading .slick-slide
	{
	    visibility: hidden;
	}
	.slick-vertical .slick-slide
	{
	    display: block;

	    height: auto;

	    border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
	    display: none;
	}

	// Customisation

	button.slick-next {
		display: inline-block;
	    border: none;
	    outline: none;
	    @include hideText;

	    background-color: transparent;
		background-image: url(../icons/svg/down-arrow-white.svg);
	    background-size: 22px 12px;
	    background-repeat: no-repeat;
	    background-position: center center;
	    bottom: 15px;
	    height: 40px;
	    left: 50%;
	    margin-left: -20px;
	    opacity: 1;
	    position: absolute;
	    width: 40px;
	    z-index: 1;

	    &.slick-disabled {
	    	bottom: -9999px;
	    }

	    &:hover {
			animation: animation 800ms linear both;
	    }
	}

	button.slick-prev {
		display: inline-block;
	    border: none;
	    outline: none;
	    @include hideText;

	    background-color: transparent;
		background: url(../icons/svg/down-arrow-white.svg);
	    background-size: 22px 12px;
	    background-repeat: no-repeat;
	    background-position: center center;

	    bottom: 9999px;

	    height: 40px;
	    left: 50%;
	    margin-left: -20px;
	    opacity: 1;
	    position: absolute;
	    width: 40px;
	    z-index: 1;
	    @include rotate(180deg);

	    &.show {
	    	bottom: 15px;
	    }
	}

	ul.animation-dots {
	    position: absolute;
	    left: -9999px;
	    position: absolute;
	    top: 50%;
		@include translateY;

	    li {
	    	text-align: center;
	    	width: 9px;

	    	button {
	    		background: #fff;
	    		border: 2px solid #fff;
	    		height: 9px;
	    		margin: 9px 0;
	    		outline: none;
	    		padding: 0px;
	    		width: 9px;
	    		@include hideText;
	    		@include bdrRadius(100%);
	    		@include trans(background);
	    	}

	    	&.slick-active {
	    		button {
	    			background: transparent;
	    		}
	    	}
	    }
	}

	.slick-list {
		height: 100%;
		width: 100%;

		.slick-track {
			height: 100%;
			width: 100%;

			> div {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				height: 100%;
				width: 100%;
			}
		}
	}

	.header {
		background: transparent;
		position: absolute;
		top: 0px;
		left:0px;
		width: 100%;

		.sub-menu {
			padding-top: 20px;
		}
	}

	.awards {
		margin: 0 auto;
		max-width: 1080px;
		padding-top: 30px;

		ul {
			overflow: hidden;

			li {
				float: left;
				padding-left: 0px;
				margin: 0 0 30px 0;
				width: 100%;

				&:first-of-type {
					margin-left: 0px;
				}

				&:last-of-type {
					margin-right: 0px;
				}

				figure {
					background: $background;
					height: 200px;
					position: relative;
					text-align: center;
					width: 100%;

					img {
						display: inline-block;
						height: 100%;
						width: auto;
					}

					figcaption {
						background: $feature;
						color: $white;
						font-size: 90px;
						font-weight: 700;
						height: 100%;
						left: 0px;
						opacity: 0;
						padding-top: 80px;
						position: absolute;
						text-align: center;
						top: 0px;
						width: 100%;
						@include trans(opacity);
					}
				}

				&:before {
					display: none;
				}

				h3 {
					color: $text;
					margin: 10px 0 0 0;
					text-align: center;
				}

				&:hover {
					figure {
						figcaption {
							opacity: 1;
						}
					}

					h3 {
						color: $feature;
					}
				}
			}
		}
	}

	.partners {
		margin: 0 auto;
		max-width: 1200px;
		padding-top: 80px;

		h2 {
			margin-left: auto;
			margin-right: auto;
			max-width: 860px;
		}

		ul {
			overflow: hidden;
			text-align: center;

			li {
				display: inline-block;
				float: none;
				padding-left: 0px;
				margin: 0 20px 50px 20px;
				width: auto;

				&:first-of-type {
					margin-left: 0px;
				}

				&:last-of-type {
					margin-right: 0px;
				}

				figure {
					height: 54px;
					position: relative;
					text-align: center;
					width: auto;

					img {
						display: inline-block;
						height: 100%;
						width: auto;
					}
				}

				&:before {
					display: none;
				}
			}
		}
	}

}

@include media('small-tablet') {

	.home {

		.awards {

			ul {
				text-align: center;

				li {
					display: inline-block;
					float: none;
					margin: 0 10px 30px;
					width: 200px;
				}
			}
		}

		.partners {

			ul {

				li {
					margin: 0 50px 70px 50px;
				}
			}
		}
	}
}

@include media('tablet') {

	.home {
		.carousel {
			p {
				font-size: 75px;
			}
		}
	}
}