.map {
	height: 330px;
}

.map--large {
	height: 100%;
}

.map-info {
	// position: relative;

	// &:before {
	// 	content: "";
	// 	border-top: 10px solid transparent;
	// 	border-bottom: 10px solid transparent; 
	// 	border-right:10px solid $white; 
	// }

	p {
		font-size: 14px;
		line-height: 20px;
		font-family: $font-family-2;
		font-weight: 700;
		margin: 0;
	}
}

.contact--details {
	overflow: hidden;
	margin-top: 52px;
}