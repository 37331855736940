.prompt {
	position: relative;
	display: block;
	text-align: center;
	width: 100%;
	clear: both;
	margin-bottom: 55px;

	&:before,
	&:after {
		content: "";
		position: absolute;
		height: 1px;
		display: block;
		background-color: $greyMid;
		top: 50%;
		width: calc(50% - 30px);
		transform: translateY(7px); // account for down arrow icon
	}

	&:before {
		left: 0;
	}

	&:after {
		right: 0;
	}
}

.prompt-button {
	font-size: em(14);
	line-height: em(18, 14);
	font-family: $font-family-2;
	color: $greyMid;
	display: inline-block;
	position: relative;
	text-decoration: none;

	&:hover {
		color: $blueDark;
	}

	&:after {
		content: "";
		display: block;
		background: url('../../dist/icons/svg/down-arrow.svg') center center / 21px 10px no-repeat;
		width: 21px;
		height: 10px;
		margin: 7px auto 0;
		@include trans(all);
	}

	&.open {
		&:after {
			@include rotate(180deg);
		}
	}
}