// Forms
// -------------------------------------
body .gform_wrapper ul li.gfield {
	padding-left: 0px;
}

.gform_wrapper ul.gform_fields li.gfield {
	&:before {
		display: none;
	}
}

.searchform {
	label {
		font-size: 18px;
		font-weight: 700;
	}

	#searchsubmit {
		@extend .button;
		display: inline;
		float: left;
	    padding-left: 75px;
	    padding-right: 75px;
	    width: auto!important;
	}
}

.select,
select {
	background-size: 8px 5px;
	color: $black;
	float: right;
	font-size: 16px;
	padding: 0 20px 0 20px;
	@include bdrRadius(0px);			
    display: block;
    border: none;
    -webkit-appearance: inherit;
    width: 100%;
}

input {
	line-height: 1.1;
	-webkit-appearance: none;
    border-radius: 0;
}

textarea {
	-webkit-appearance: none;
    border-radius: 0;
}

.hide-label {
	label {
		display: none!important;
	}
}

.gform_wrapper .top_label input.medium {
	width: 100%!important;
}

form {
	clear: both;
	float: left;
	padding: 0px!important;
	width: 100%;

	label {
	}

	input {
		border: none;
		border-bottom: 1px solid $feature;
		font-size: 18px;
		height: 40px;
		margin-bottom: 30px;
		width: 100%;
	}

	textarea {
		background: $background;
		border: none;
		border-bottom: 1px solid $feature;
		font-size: 18px;
		height: 40px;
		margin-bottom: 30px;
		width: 100%;
	}

	input[type=submit]{
	    height: auto;
	    border-radius: 0px;
	    line-height: 1;
	    margin: 0px;
	    padding-left: 75px;
	    padding-right: 75px;
	}

	div.checkboxes {
		input {
			display: none!important;
		}

		label {
			display: block!important;
			margin: 10px 0;
			padding-left: 30px;
			position: relative;

			&:before {
				content: "";
				height: 18px;
				left: 0px;
				position: absolute;
				top: 2px;
				width: 18px;
			}
			
			&:after {
				border: 3px solid #fff;
				content: "";
				height: 16px;
				left: 1px;
				position: absolute;
				top: 3px;
				width: 16px;
			}

			&.checked:before {
			}
		}
	}

	div.radio-buttons {
		input {
			display: none!important;
		}

		label {
			display: block!important;
			line-height: 1.2;
			margin: 10px 0;
			padding-left: 30px;
			position: relative;

			&:before {
				border-radius: 100%;
				content: "";
				height: 20px;
				left: 0px;
				position: absolute;
				top: 0px;
				width: 20px;
			}
			
			&:after {
				background: transparent;
				border-radius: 100%;
				content: "";
				height: 10px;
				left: 5px;
				position: absolute;
				top: 5px;
				width: 10px;
			}

			&.checked:after {
			}
		}
	}
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
	margin-top: -17px!important;
    display: block!important;
    padding-left: 0px!important;
}

// Gravity Forms

.gform_confirmation_wrapper  {
	font-size: 18px;
}

.gform_wrapper {
	max-width: 860px !important;
    margin: 0 auto !important;
    margin-bottom: 55px!important;
    overflow: hidden;
    // padding: 0 30px;
}

.modal {
	.gform_wrapper {
		margin: 0px!important;
	}

	.gform_title {
		font-weight: 400!important;
		font-size: em(30)!important;
		line-height: em(20, 30);
		margin-bottom: 25px!important;
		margin-top: 0px!important;
	}

	.gform_description {
		font-size: em(20);
		line-height: em(20, 20);
		margin-bottom: 50px!important;
		display: block;
	}

	.gfield_label {
		font-size: em(20)!important;
		font-weight: 400!important;
		line-height: em(20, 20);
		margin-bottom: 20px;
		display: block;
	}

	.ginput_container {
		margin-top: 0px!important;

		input:not([type=submit]), 
		textarea {
			border: none;
			width: 100%!important;
			margin-bottom: 20px;

			&:active,
			&:focus {
				background-color: $white;
			}
		}
	}

	.gform_footer {
		padding-top: 20px;

		input[type=submit] {
			width: auto;
			border: none;
			@extend .button;
			font-size: em(16);
			padding-left: 25px;
			padding-right: 25px;
		}
	}
}

@include media('small-tablet'){
	form {
		div.radio-buttons {
			label {
				margin: 0 0 0 10px;
			}
		}
	}
}
