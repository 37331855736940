.story-wrapper {
	margin-bottom: 50px;
	overflow: hidden;
	padding-top: 30px;
	position: relative;

	&:after {
		background: $border;
		content: "";
		height: 100%;
		position: absolute;
		top: 30px;
		left: 50%;
		width: 1px;
	}
}

.date {
	background-color: #fff;
	display: inline-block;
	margin: 0 0 40px 0;
	padding: 20px 20px 0 20px;
	position: relative;
	text-align: center;
	z-index: 1;

	h2 {
	    display: inline-block;
		margin: 0 0 10px 0;
		padding: 0px;
		position: relative;
	    width: auto;

		&:after {
			display: none;
		}

		&:before {
			background: #fff url(../images/O.svg) 0 0 no-repeat;
			background-size: 100%;
			content: "";
			height: 30px;
			position: absolute;
			top: 0px;
			left: -40px;
			width: 30px;
		}
	}
}

@include media('small-tablet'){
	.story-wrapper {
		margin-bottom: 100px;
		padding-top: 60px;		

		&:after {
			top: 60px;
		}
	}

	.date {
		clear: both;
		float: left;
		padding-top: 0px;
		width: 49.5%;

		h2 {
			margin-bottom: 0px;
			
			&:before {
				top: 5px;
			}
		}

		&:nth-child(odd){
			float: right;
			margin-left: 10px;
			right: -100%;
			text-align: left;
			transition: right 1s ease-in-out;

			h2 {
				&:before {
					left: -41px;
				}
			}

			&.visible {
				right: 0;
			}
		}

		&:nth-child(even){
			left: -100%;
			text-align: right;
			transition: left 1s ease-in-out;

			h2 {
				float: right;

				&:before {
					left: auto;
					right: -41px;
				}
			}

			p {
				clear: both;
				float: right;
			}

			&.visible {
				left: 0;
			}
		}

		p {
			max-width: 390px;
		}
	}
}