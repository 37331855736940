/*
font-family: "aktiv-grotesk",sans-serif;
font-style: normal;
font-weight: 300;

font-family: "aktiv-grotesk",sans-serif;
font-style: normal;
font-weight: 700;
*/

h1,
.h1-faux {
	font-size: em(30, 18);
	line-height: em(37, 30);
	font-weight: 700;
	text-align: center;
	font-family: $font-family;
	display: block;
}

h2,
.h2-faux {
	font-size: em(25, 18);
	line-height: em(30, 24);
	font-weight: 700;
	font-family: $font-family;
	margin-bottom: 35px;
	padding-bottom: 25px;
	text-align: center;
	width: 80%;
	position: relative;

	&:after {
		content: "";
		height: 1px;
		background-color: $greyLight;
		position: absolute;
		display: block;
		left: 2px;
		right: 2px;
		bottom: 0px;
	}
}

.stuck h2,
.stuck h2.h1-faux {
	margin-bottom: 0px;
}

.home h2,
section.carousel-wrapper h2,
.contact h2,
.single h2 {
	width: 100%;
}

.contact h2 {
	padding-bottom: 0px;
    margin-top: 52px;

    &:after {
    	display: none;
    }
}

.search h2 {
	padding-bottom: 0px;

    &:after {
    	display: none;
    }
}

h3,
.h3-faux {
	color: $feature;
	font-size: em(20, 18);
	line-height: em(30, 24);
	font-weight: 700;
	font-family: $font-family;
	margin-bottom: 35px;

	&.search-result {
		margin-bottom: 5px;
	}
}

h4,
.h4-faux {
	font-size: em(24);
	line-height: em(38, 32);
	font-family: $font-family;
	font-weight: 400;
	margin-bottom: 45px;
}

h5,
.h5-faux {
	font-size: em(16);
	line-height: em(24, 16);
	font-weight: 400;
	margin-bottom: em(30, 16);
	font-family: $font-family;
}

.subtitle {
	font-weight: 400;
	width: 100%;
	text-align: center;
	margin-bottom: 30px;
	text-transform: uppercase;
}

.title--quiet {
	font-weight: 400;
}

h4 {
	&.title--quiet {
		font-size: 32px;
	}
}

ul li,
ol li {
	font-size: em(18);
	line-height: em(24, 16);
	font-weight: 400;
}

p {
	font-size: em(18);
	line-height: em(24, 16);
	font-weight: 400;
	margin-bottom: em(30, 16);
	font-family: $font-family;

	&.small {
		font-size: em(15);
		line-height: em(20, 15);
		margin-bottom: em(25, 15);
	}

	&.large {
		font-size: em(24);
		line-height: em(30, 24);
		font-family: $font-family;

		a {
			color: $greyDark;
			text-decoration: none;
			font-weight: 400;
		}
	}

	strong {
		font-weight: 700;
	}

	em {
		font-style: italic;
	}
}

// .lead,
// .lead p,
// .lead h5,
// .content p:first-of-type strong {
// 	font-family: $font-family;
// 	font-weight: 400;
// }

a {
	color: $feature;
	text-decoration: none;
	transition: color 0.2s linear;

	&:hover {
		color: $text;
	}

	&.less,
	&.more {
		display: inline-block;
		position: relative;
		color: $blueDark;

		// &.lonely {
		// 	display: block;
		// }
	}

	&.less {
		text-decoration: none;
		padding-left: 40px;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 30px;
			height: 13px;
			background: url('../../dist/icons/svg/arrow-right-grey.svg') center center / 30px 13px no-repeat;
			transform: rotate(180deg);
		}
	}

	&.more {
		text-decoration: none;
		padding-right: 40px;

		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: 30px;
			height: 13px;
			background: url('../../dist/icons/svg/arrow-right-grey.svg') center center / 30px 13px no-repeat;
		}
	}
}

div.step-paginate {
	a {
		color: $blue;
		transition: color 0.2s linear;
		display: inline-block;
		position: relative;
		color: $blueDark;

		&:hover {
			color: $blueDark;
		}
	}

	&.less a {
		text-decoration: none;
		padding-left: 40px;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 30px;
			height: 13px;
			background: url('../../dist/icons/svg/arrow-right-grey.svg') center center / 30px 13px no-repeat;
			transform: rotate(180deg);
		}
	}

	&.more a {
		text-decoration: none;
		padding-right: 40px;

		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: 30px;
			height: 13px;
			background: url('../../dist/icons/svg/arrow-right-grey.svg') center center / 30px 13px no-repeat;
		}
	}
}

blockquote {
	background: url('../../dist/images/quote.svg') 0 7px / 26px 30px no-repeat;
	line-height: 1.2em;
	padding: 0 0 0 50px;
	font-family: $font-family;
	font-size: em(20);
	font-weight: 700;
	margin-bottom: 30px;

	p {
		font-weight: 700;
		margin-bottom: 0px;
	}

	strong {
		font-size: em(16, 20);
		font-weight: 400;
	}
}

@include media('tablet') {

}

@include media('desktop') {
	h2,
	.h2-faux {
		font-size: em(30);
		margin-bottom: 55px;
		padding-bottom: 45px;
	}

	blockquote {
		background: url('../../dist/images/quote.svg') 0 7px / 52px 59px no-repeat;
		padding: 0 0 0 90px;
		font-size: em(26);
		
		strong {
			font-size: em(20, 30);
		}
	}
}

// Typekit's FOUT

// .wf-loading {
// 	opacity: 0.5;
// }

// .wf-active {
// 	transition: all 0.2s linear;
// 	opacity: 1;
// }