section.video {
	margin-bottom: 30px;
}

section.logos {
	ul {
		text-align: center;
		width: 100%;

		li {
			display: inline-block;
			float: none;
			padding: 0 10px;

			&:before {
				display: none;
			}
		}
	}
}

section.carousel-wrapper {
	h2 {
		margin-bottom: 0px;

		&:after {
			display: none;
		}
	}
}

.contact .article {
	padding-top: 0px;
}

.article {
	padding-top: 24px;
	padding-bottom: 74px;

	ul, ol {
		margin-bottom: 30px;
	}

	li {
		//font-size: em(20);
		line-height: em(30, 20);
		padding-left: 20px;
		position: relative;
		margin-bottom: 10px;

		&:before {
			content: "";
			width: 8px;
			height: 8px;
			border-radius: 50%;
			display: block;
			position: absolute;
			background-color: $feature;
			left: 0;
			//top: 10px;
			top: 10px;
		}
	}

	ol {
		list-style: decimal;

		li {
			margin-left: 15px;
			padding-left: 5px;

			&:before {
				display: none;
			}
		}
	}

	ul.meta {
		overflow: hidden;

		li {
			float: left;
			margin-right: 30px;
			padding-left: 0px;

			&:before {
				display: none;
			}
		}
	}

	img {
		display: block;
		margin-bottom: 35px;
	}
}

.article--flush-bottom {
	padding-bottom: 0;
}

.article--flush-top {
	padding-top: 0;
}

.article--small-top {
	padding-top: 20px;
}

.article-bordered {
	position: relative;

	&:after {
		content: "";
		height: 1px;
		background-color: $greyLight;
		position: absolute;
		display: block;
		left: 10px;
		right: 10px;
	}
}

.article-module {
	box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.1);
	background-color: $white;
	margin-bottom: 20px;
}

.article-post {
	background: $white;
	@include clearfix();
	padding: 35px 20px;
}

.article-header {
	padding: 120px 40px 150px;
	position: relative;
	text-align: center;
	background-size: cover;
	background-position: center center;
	color: $white;

	.icon {
		display: block;
		margin: 0 auto 15px;
		width: 35px;
		height: 35px;
	}

	p {
		position: absolute;
		bottom: 0px;
		text-align: center;
		width: 100%;
		left: 0px;
		font-size: 20px;
	}
}

.article-footer {

	.less {
		margin-bottom: 15px;
	}

	.col {
		text-align: center;
		margin-bottom: 10px;

		.less:nth-child(1),
		.more {
			margin-top: 10px;
		}
	}
}

.article-aside-content {
	padding: 20px;

	ul {
		margin: 0;
	}
}

.article-aside-image {
	width: 100%;
	height: auto;
	display: block;
}

.article-aside-title {
	font-size: em(24);
	line-height: em(48, 24);
	font-weight: 400;
	margin-bottom: 0;
}

.article-aside-title--padded {
	margin-bottom: 10px;
}

.article-aside-subtitle {
	font-size: em(16);
	line-height: em(24, 16);
	margin-bottom: 10px;
	font-weight: 400;
	display: block;
	font-family: $font-family-2;
}

.article-aside-copy {
	margin: 0;
}

.contact {
	.container--inner {
		margin: 0 auto;
		max-width: 860px;

		div.left,
		div.right {
			width: 100%;
		}
	}
}

.jump-nav {
	margin-bottom: 20px;
	width: 100%;

	.jump-title {
		border: 1px solid $background;
		color: $text;
		display: block;
		font-size: 1.125em;
		line-height: 1.5em;
		padding: 10px;
		position: relative;
		width: 100%;

		&:before {
			background: $feature;
			content: "";
			height: 2px;
			position: absolute;
			right: 14px;
			top: 50%;
			transform: rotate(-45deg);
			width: 10px;
			@include trans(right);
		}

		&:after {
			background: $feature;
			content: "";
			height: 2px;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: rotate(45deg);
			width: 10px;
			@include trans(right);
		}

		&.active {
			&:before {
				right: 20px
			}

			&:after {
				right: 14px
			}
		}
	}

	ul {
		border: 1px solid $background;
		border-bottom: none;
		border-top: none;
		display: none;
		margin-bottom: 0px;

		li {
			border-bottom: 1px solid $background;
			margin-bottom: 0px;
			padding-left: 1px;

			&:before {
				display: none;
			}

			a {
				background: #fff;
				color: $text;
				display: block;
				font-size: 18px;
				padding: 10px;
				@include trans(all);

				&:hover {
					background: $feature;
					color: #fff;
				}
			}
		}
	}
}

@include media('small-tablet'){
	.contact {
		.container--inner {
			overflow: hidden;
			
			div.left,
			div.right {
				float: left;
				width: 300px;
			}
		}
	}
}

@include media('tablet') {

	.article {
		padding-top: 64px;
	}

	.article-main {

		&:after {
			left: 20px;
			right: 20px;
		}
	}

	.article-post {
		padding: 35px 80px;
	}

	.article-header {
		padding: 120px 33.33333% 150px;
		text-align: center;
	}

	.article-footer {

		.col {

			&.u-align-left {
				text-align: left;
			}

			&.u-align-right {
				text-align: right;
			}
		}
	}

	.jump-nav {
		display: none;
	}
}

@include media('desktop'){
	.archive-image {
	    float: right;
	    margin: 0 0 40px 40px;
		width: 308px;
	}

	section.logos {
		ul {

			li {
				padding: 0 30px;
			}
		}
	}
}