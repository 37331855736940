@media print {

	header.header,
	footer.footer,
	div.sharing,
	article.carousel-outer-wrapper,
	section.video {
		display: none!important;
	}

	.listing--people .team-bio {
		display: block!important;
	}

	section,
	div,
	article {
		background: #fff url(none);
		float: none;
		margin: 0;
		width: 100%;
	}

	body,
	article {
		padding-top: 0px!important;
	}

	.header-title-container, .header-title-container--sub {
		height: auto;
		padding: 0px;
	}

}