// Box sizing
// -------------------------------------

html {
	background-color: $feature;
	box-sizing: border-box;
	padding-top: 8px;
}

html,
body {
	min-height: 100%;
	font-family: $font-family;
	color: $greyDark;

	&.no-scroll {
		overflow: hidden;
		height: 100%;
	}

	&:before {
		background: $feature;
		content: "";
		height: 8px;
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		z-index: 100;
	}
}

body {
	background-color: $white;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

iframe {
	display: block;
}

img {
	height: auto;
	width: 100%;
}

// Body styling
// -------------------------------------

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.site-wrapper {
	transition: all 0.3s ease-in;
	min-height: 100%;

	&.open {
		transform: translateX(210px);
	}
}

body.scrolling {
	padding-top: 94px;

	header.header {
		opacity: 0;
	}
}


// Responsive Video
// -------------------------------------
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin-bottom: 40px;
}

.embed-container iframe, 
.embed-container object, 
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


@include media('tablet') {

	// .site-wrapper {
	// 	padding-top: 84px;

	// 	&.open {
	// 		transform: translateX(0);
	// 	}
	// }
}

@include media('desktop') {

	body.scrolling {
		padding-top: 75px;
	}
}