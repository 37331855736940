.button {
	-webkit-appearance: none;
	margin: 0 auto;
	text-decoration: none;
	display: inline-block;
	transition: all 0.2s linear;
	font-family: $font-family;

	background: $feature;
	color: $white;
	font-weight: 700;
	display: block;
	padding: 18px;
	text-align: center;
	width: 100%;

	&:hover {
		background-color: $text;
		color: $white;
	}
}

.button--small {
	padding: 5px 10px;
}