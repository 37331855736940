.project {

	iframe {
		width: 100%;
		margin-bottom: 18px;
	}
}

.project--light {
	padding-top: 64px;
	padding-bottom: 74px;
}

.project-header {
	clear: both;
	@include clearfix();
	position: relative;
	margin-bottom: 18px;
	min-height: 38px; // height of share icons
}

.project-header--border {
	min-height: 56px; // height as above plus padding
	border-bottom: 1px solid $greyLight;

	.social--share {
		margin-bottom: 20px;
	}

	.project-title {
		padding-right: 0;
	}
}

.project-title {
	font-size: em(24);
	line-height: em(30, 24);
	font-family: $font-family-2;
	display: block;
	padding-right: 145px;
	margin-bottom: 18px;
}

.project-title--small {
	font-size: em(16);
	line-height: em(25);
	font-weight: 400;
	font-family: $font-family;
	padding-top: 10px;
}

.project-meta {
	padding: 20px 0;
	border-top: 1px solid $greyLight;
	border-bottom: 1px solid $greyLight;
	@include clearfix();
	margin-bottom: 18px;
}

.project-meta-item {
	font-family: $font-family-2;
	margin-bottom: 20px;
}

.project-footer {
	padding: 20px 0;
	border-top: 1px solid $greyLight;
	margin-bottom: 20px;
}

.project-category {
	display: inline-block;
	margin: 0 10px 30px 0;

	a {
		@extend .button;
		padding: 10px;
	}

	&.project-category--featured a {
		background-image: url(../icons/svg/pin-1.svg);
		background-repeat: no-repeat;
		background-position: 10px 10px;
		background-size: 16px 16px;
		padding-left: 35px;
	}
}

.category-hidden {
	display: none;
}

li.project-category {
	padding: 0;

	&:before {
		display: none;
	}
}

@include media('tablet') {

	.project-title {
		margin-bottom: 0;
	}

	.project-meta-item {
		width: 33.333333333%;
		float: left;
		padding-right: 10px;
		margin-bottom: 0;

		&:nth-child(3) {
			padding-right: 0;
		}
	}
}