.icon {

}

.icon--linkedin {
	width: 16px;
	height: 16px;
	fill: $blueMid;
}

.icon--linkedin--large {
	width: 24px;
	height: 24px;
	fill: $blueMid;
}

.icon--twitter {
	width: 16px;
	height: 16px;
	fill: $blueMid;
}

.icon--facebook {
	width: 16px;
	height: 16px;
	fill: $blueMid;
}

.icon--houzz {
	width: 16px;
	height: 16px;
	fill: $blueMid;
}

.icon--phone {
	width: 14px;
	height: 14px;
	fill: $blueMid;
}

.icon--mail {
	width: 14px;
	height: 14px;
	fill: $blueMid;
}

.icon--facebook-share {
	width: 38px;
	height: 38px;
	fill: #43619c;
}

.icon--twitter-share {
	width: 38px;
	height: 38px;
	fill: #24a9e6;
}

.icon--linkedin-share {
	width: 38px;
	height: 38px;
	fill: #187fb8;
}

.icon--arrow-right {
	width: 40px;
	height: 17px;
	fill: $white;
    float: right;
    margin-top: 23px;
}

.icon--notebook {
	width: 25px;
	height: 24px;
	fill: $white;
}

.icon--less {
	width: 27px;
	height: 27px;
	fill: $greyDark;
}

.icon--more {
	width: 27px;
	height: 27px;
	fill: $greyDark;
}