.container--cat .col {
	float: none;
	margin: 0 auto;
}

.category-filters {
	display: none;
	padding: 26px 20px 0;
	width: 100%;

	ul {
		li {
			font-size: em(16);
			margin-bottom: 30px;
			width: 100%;

			a {
				color: $text;
				display: inline-block;
				font-weight: 700;
				text-align: center;
				width: 100%;

				&:hover,
				&.active {
					color: $feature;
				}
			}
		}
	}
}

.category-arts,
body.arts {
	.category-filters ul li.cat-2 a {
		color: $feature;
	}
}

.category-documentary,
body.documentary {
	.category-filters ul li.cat-3 a {
		color: $feature;
	}
}

.category-specialist-factual,
body.specialist-factual {
	.category-filters ul li.cat-4 a {
		color: $feature;
	}
}

.category-drama,
body.drama {
	.category-filters ul li.cat-5 a {
		color: $feature;
	}
}

.category-branded-film,
body.branded-film {
	.category-filters ul li.cat-6 a {
		color: $feature;
	}
}

@include media('desktop') {
	.category-filters {
		display: block!important;
		padding-bottom: 26px;

		ul {
			display: inline-block;
			overflow: hidden;
			text-align: center;
			width: 100%;

			li {
				border-right: 1px solid $border;
				display: inline-block;
				float: none;
				height: 34px;
				margin-bottom: 0px;
				padding: 8px 38px 0;
				width: auto;

				&:last-of-type {
					border-right: none;
				}
			}
		}
	}
}

@include media('large-desktop') {
	.category-filters {
		ul {

			li {
				height: 54px;
				padding: 18px 50px 0;
			}
		}
	}
}

@include media('largest') {
	.category-filters {
		ul {

			li {
				height: 54px;
				padding: 18px 70px 0;
			}
		}
	}
}