.services {
	@include clearfix();
	margin-bottom: 20px;
}

.service {
	position: relative;
	padding-left: 35px;
	margin-bottom: 20px;

	&:before {
		content: "";
		position: absolute;
		display: block;
		background: url('../../dist/icons/svg/tick.svg') center center / 23px 14px no-repeat;
		width: 23px;
		height: 14px;
		left: 0;
		top: 2px;
	}

		
}

@include media('tablet') {

	.service {
		width: 50%;
		float: left;

		&:nth-child(odd) {
			padding-right: 10px;
			clear: left;
		}

		&:nth-child(even) {
			padding-left: 45px;

			&:before {
				left: 10px;
			}
		}
	}
}